import React from "react"
import Typography from "@components/ui/Typography/Typography"
import Icon from "@components/ui/Icon/Icon"
import { Button } from "@components/ui/Button"
import Image from "@components/ui/Image/Image"
import ImageTopper from "@components/ui/Image/Topper"
import { useFormikContext } from "formik"
import { EditAssetGroupFormValues } from "@pages/PMax/components/EditAssetGroupPage/EditAssetGroupForm"
import { observer } from "mobx-react-lite"
// import AssetLogosSidebar from "@pages/PMax/components/EditAssetGroupPage/Assets/AssetGroupLogosSidebar"
import styles from "./Assets.module.scss"

const Logos = observer(() => {
  const { values } = useFormikContext<EditAssetGroupFormValues>()
  // const [isOpen, setIsOpen] = React.useState(false)
  const logos = values.logos && values.logos.length ? values.logos : []
  return (
    <div className={styles.section}>
      <Typography className={styles.headTitle} type="h2">
        Logos
      </Typography>
      <Typography type="subtitle" color="gray">
        Add up to 20 logos
      </Typography>
      <div className={styles.images}>
        {logos.slice(0, 3).map((logo, i) => (
          <Image src={logo} className={styles.image} key={`${i + 1}-logo`} />
        ))}
        <ImageTopper className={styles.image} message="+">
          <Image key="last-image" />
        </ImageTopper>
      </div>

      <Button
        color="primary"
        before={<Icon name="plus" key="plus" />}
        variant="ghost"
        className={styles.buttonIcon}
        // onClick={() => setIsOpen(true)}
      >
        Logo
      </Button>
      {/* <AssetLogosSidebar isOpen={isOpen} onClose={() => setIsOpen(false)} /> */}
    </div>
  )
})

export default Logos
