import React from "react"
import { ID } from "@framework/types/types"
import { CampaignChild } from "@framework/types/account"
import { throttle } from "lodash"

const emptyArray: any[] = []

export const useFilteredAdGroupChild = <T extends CampaignChild>(config: {
  collection: T[] | null
  campaigns: ID[]
}) => {
  const { collection, campaigns } = config

  const [inProgress, setInProgress] = React.useState(false)

  const [filteredCollection, setFilteredCollection] =
    React.useState<T[]>(emptyArray)

  const filterList = React.useCallback(
    throttle((campaigns: ID[]) => {
      setInProgress(true)

      if (!campaigns.length || !collection?.length)
        setFilteredCollection(emptyArray)
      else {
        const capmSet = new Set(campaigns.map((it) => it.toString()))
        const res = collection.filter((it) =>
          capmSet.has(it.CampaignId.toString())
        )
        setFilteredCollection(res)
      }

      setInProgress(false)
    }, 1_000),
    [collection]
  )

  React.useEffect(() => {
    setInProgress(true)
    filterList(campaigns)
  }, [filterList, campaigns])

  return { filteredCollection, inProgress }
}

export default useFilteredAdGroupChild
