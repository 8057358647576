import { observable } from "mobx"
import { BaseCampaignReportType } from "@framework/types/account"
import TopCampaignStore from "./top-campaigns.store"
import KeywordsStore from "./keywords.store"

class TopKeywordsStore {
  // INJECTIONS

  @observable campaignStore: TopCampaignStore<BaseCampaignReportType>

  @observable keywordsStore: KeywordsStore

  // CONSTRUCTOR

  constructor(injections: {
    campaignStore: TopCampaignStore<BaseCampaignReportType>
    keywordsStore: KeywordsStore
  }) {
    this.campaignStore = injections.campaignStore
    this.keywordsStore = injections.keywordsStore
  }

  // STATE
}

export default TopKeywordsStore
