import React from "react"
import isUndefined from "lodash/isUndefined"

import { MapperType } from "@components/ui/WaterfallTable/types"
import { statusToLabel } from "@utils/stringUtils"
import { MetricType } from "@framework/types/metrics"
import KPIsMapper from "./kpi-table-mapper"
import CpcForm from "./CpcForm"
import Status from "./Status"

export const getMapper = (): MapperType[] => {
  const list: MapperType[] = []

  return list.concat([
    {
      name: "Status",
      label: "Status",
      sortable: true,
      widthBasis: "45px",
      renderCallback: (value: string, data: any, name) =>
        !isUndefined(value) && (
          <Status
            entityType="keyword"
            name={name}
            value={value}
            data={data}
            editable={data.editable}
            keywordId={data.Id}
            adGroupId={data.AdgroupId}
            campaignId={data.CampaignId}
          />
        ),
    },
    {
      name: "MatchType",
      label: "Match Type",
      renderCallback: (value: string) => statusToLabel(value),
      sortable: true,
    },
    {
      name: "CampaignName",
      label: "Campaign",
      sortable: true,
      widthBasis: "120px",
    },
    {
      name: "AdGroupName",
      label: "Ad Group",
      sortable: true,
      widthBasis: "120px",
    },
    {
      name: "CpcBid",
      label: "Max CPC $",
      sortable: true,
      renderCallback: (value: MetricType, data, name) =>
        !isUndefined(value) && (
          <CpcForm
            name={name}
            value={value.Value.toString()}
            data={data}
            editable={data.editable}
            adGroupId={data.AdgroupId}
            adgroupName={data.AdGroupName}
            keywordId={data.Id}
            entityType={data.type}
          />
        ),
    },

    ...KPIsMapper,
  ])
}

export const mapper = getMapper()

export default mapper
