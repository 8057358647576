import * as yup from "yup"

const editAssetGroupSchema = yup.object({
  headline: yup
    .array()
    .of(yup.string())
    .max(15)
    .min(3)
    .transform((value) => (value === null ? undefined : value))
    .default([]),
  longHeadline: yup
    .array()
    .of(yup.string())
    .max(5)
    .min(1)
    .transform((value) => (value === null ? undefined : value))
    .default([]),
  description: yup
    .array()
    .of(yup.string())
    .max(5)
    .min(2)
    .transform((value) => (value === null ? undefined : value))
    .default([]),
  finalUrl: yup.string().required(),
  siteLinks: yup
    .array()
    .of(
      yup.object({
        description1: yup.string().required(),
        description2: yup.string().required(),
        // startDate: yup.string(),
        // endDate: yup.string(),
        linkText: yup.string(),
        finalUrl: yup.string().required(),
      })
    )
    .max(20)
    .min(2),
  youtubeVideos: yup
    .array()
    .of(yup.object({ url: yup.string().required() }))
    .max(5)
    .transform((value) => (value === null ? undefined : value)),
})

export default editAssetGroupSchema
