import React from "react"
import clsx from "clsx"

import Button from "@components/ui/Button/Button"
import Templates from "@components/ui/Templates"
import Icon from "@components/ui/Icon/Icon"
import useAddKeywordsModal from "../CampaignTable/useAddKeywordsModal"

export type TableHeaderProps = {
  className?: string
}

export const KeywordsTableControl: React.FC<TableHeaderProps> = ({
  className,
}) => {
  const modal = useAddKeywordsModal()

  return (
    <>
      <Templates.Header
        right={
          <Button
            onClick={modal.open}
            before={<Icon name="plus" />}
            variant="outlined"
            size="big"
          >
            Add Keywords
          </Button>
        }
        className={clsx(className)}
      />

      {modal.Modal}
    </>
  )
}

export default KeywordsTableControl
